import { I18n } from "i18n";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { Table as TableUI, Tag } from "antd";
import { DataPoint } from "types";
import { GetDataPoints } from "apollo/data-points";
import { useState } from "react";

const { Column } = TableUI;

interface NameProps {
  onShowDrawer: (_id?: string) => void;
}

export function ListDataPoints({ onShowDrawer }: NameProps) {
  const history = useHistory();
  const { data: dataDataPoints, loading: isLoading, error } = GetDataPoints();
  const [filteredStatus, setFilteredStatus] = useState<boolean[] | null>([true]);
  function findFilters(data: any, key: any) {
    let out = data.map((item: any) => item[key].name);
    let find = out.reduce((memo: any, key: any) => {
      if (memo.includes(key)) return memo;
      memo.push(key);
      return memo;
    }, []);
    return find.map((value: any) => ({ text: I18n(value, true), value }));
  }
  if (error) history.push("/error-server");

  return (
    <TableUI
      dataSource={dataDataPoints}
      pagination={{pageSize: 4, showSizeChanger: false}}
      loading={isLoading}
      bordered={true}
      rowKey={(data) => data._id}
      onRow={(data: DataPoint, rowIndex) => {
        return {
          onClick: () => onShowDrawer(data?._id),
        };
      }}
      onChange={(pagination, filters) => {
        const statusFilters = filters.active as boolean[] | null;
        setFilteredStatus(statusFilters && statusFilters.length ? statusFilters : null);
      }}
    >
      {["name", "longitude", "latitude"].map((field) => (
        <Column title={I18n(field, true)} dataIndex={field} key={field} />
      ))}
      <Column
        title={I18n("customer", true)}
        dataIndex={"customer"}
        key={I18n("customer", true)}
        filters={findFilters(dataDataPoints || [], `customer`)}
        render={(key) => key.name}
      />
      <Column
        title={I18n("modules", true)}
        dataIndex={"module"}
        key={I18n("module", true)}
        render={(key) => (key ? I18n(key, true) : null)}
      />
      <Column
        title={I18n("location", true)}
        dataIndex={"location"}
        key={I18n("location", true)}
        render={(key) => (key ? I18n(key, true) : I18n("no_location"))}
      />
      <Column
        title={I18n("state", true)}
        dataIndex={"active"}
        key={"active"}
        filteredValue={filteredStatus || null}
        filters={[
          { text: "Activo", value: true },
          { text: "Inactivo", value: false },
        ]}
        onFilter={(value, record: any) => record.active === value}
        render={(value) => {
          return (
            <>
              {value && value === true ? (
                <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={"success"}>
                  {`  ${I18n("active", true)}`}
                </Tag>
              ) : (
                <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={"error"}>
                  {`  ${I18n("inactive", true)}`}
                </Tag>
              )}
            </>
          );
        }}
      />
      <Column
        key="action"
        render={() => [
          <span style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </span>,
        ]}
      />
    </TableUI>
  );
}