import { I18n } from "i18n";
import { Alert } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { Table as TableUI, Tag } from "antd";
import { GetUsers } from "apollo/users";
import { User } from "types";
import { Avatar } from "components";
import { useState } from "react";

const { Column } = TableUI;

interface NameProps {
  onShowDrawer: (_id?: string) => void;
}

export function ListUsers({ onShowDrawer }: NameProps) {
  const { data: dataUsers, loading: isLoading, error } = GetUsers();
  const [filteredStatus, setFilteredStatus] = useState<boolean[] | null>([true]);
  if (error && dataUsers)
    return <Alert message={I18n("internal_server_error")} type="error" closable />;

  return (
    <>
      <TableUI
        dataSource={dataUsers}
        pagination={{pageSize: 4, showSizeChanger: false}}
        loading={isLoading}
        bordered={true}
        onChange={(pagination, filters) => {
          const statusFilters = filters.active as boolean[] | null;
          setFilteredStatus(statusFilters && statusFilters.length ? statusFilters : null);
        }}
        rowKey={(data: User, i) => data?.name + i}
        onRow={(user: User, rowIndex) => {
          return {
            onClick: () => onShowDrawer(user?._id),
          };
        }}
        rowClassName={(record: object) => {
          const farm: User = record as User;
          return farm.active ? "" : "disabled-row";
        }}
      >
        <Column
          title={I18n("name", true)}
          dataIndex={"name"}
          key={"name"}
          render={(value) => {
            return <Avatar text={I18n(value, true)} />;
          }}
        />
        <Column
          title={I18n("email", true)}
          dataIndex={"email"}
          key={"email"}
          render={(value, user: any) => {
            return (
              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                <>{value}</>
                {user.verified ? (
                  <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={"success"}>
                    {`  ${I18n("verified")}`}
                  </Tag>
                ) : (
                  <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={"error"}>
                    {`  ${I18n("no_verified")}`}
                  </Tag>
                )}
              </div>
            );
          }}
        />
        <Column title={I18n("user_name", true)} dataIndex={"username"} key={"username"} />
        <Column
          title={I18n("permissions", true)}
          dataIndex={"roles"}
          render={(roles) => (
            <div>{I18n(roles.map((r: string) => I18n(r, true)).join(", "), true)}</div>
          )}
          key={"roles"}
        />
        <Column
          title={I18n("language", true)}
          dataIndex={"language"}
          key={"language"}
          render={(value) => I18n(value, true)}
        />
        <Column
          title={I18n("state", true)}
          dataIndex={"active"}
          key={"active"}
          filteredValue={filteredStatus || null}
          filters={[
            { text: "Activo", value: true },
            { text: "Inactivo", value: false },
          ]}
          onFilter={(value, record: any) => record.active === value}
          render={(value) => {
            return (
              <>
                {value ? (
                  <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={"success"}>
                    {`  ${I18n("activate")}`}
                  </Tag>
                ) : (
                  <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={"error"}>
                    {`  ${I18n("inactive")}`}
                  </Tag>
                )}
              </>
            );
          }}
        />
        <Column
          key="action"
          render={() => [
            <span style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </span>,
          ]}
        />
      </TableUI>
    </>
  );
}
