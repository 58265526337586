import { Table as TableUI, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { useCustomers } from "apollo/customers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  faClock,
  faCheck,
  faPlane,
  faListCheck,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { ObjectString } from "utils";
import { Dropdown } from "components";
import { Customer } from "types";
import { I18n } from "i18n";

const { Column } = TableUI;

interface NameProps {
  onShowDrawer: (id: string) => void;
}

const STATE: ObjectString = {
  configure_basic: { color: "default", icon: faClock },
  configure_users: { color: "yellow", icon: faClock },
  configure_data_points: { color: "yellow", icon: faClock },
  configure_devices: { color: "yellow", icon: faClock },
  Finished: { color: "green", icon: faCheck },
};

const TYPE: ObjectString = {
  test: { color: "default", icon: faListCheck },
  pilot: { color: "yellow", icon: faPlane },
  client: { color: "green", icon: faCheck },
};

export function CustomersList({ onShowDrawer }: NameProps) {
  const history = useHistory();
  const { data, error, loading } = useCustomers();
  const [filteredStatus, setFilteredStatus] = useState<boolean[] | null>([true]);
  if (error) history.push("/error-server");
  return (
    <>
      <TableUI
        dataSource={data}
        pagination={{pageSize: 4, showSizeChanger: false}}
        loading={loading}
        bordered={true}
        rowClassName={(record: object) => {
          const farm: Customer = record as Customer;
          return farm.active ? "" : "disabled-row";
        }}
        onRow={(farm: Customer, rowIndex) => {
          return {
            onClick: () => onShowDrawer(farm._id),
          };
        }}
        onChange={(pagination, filters) => {
          const statusFilters = filters.active as boolean[] | null;
          setFilteredStatus(statusFilters && statusFilters.length ? statusFilters : null);
        }}
      >
        {["name"].map((field: string) => (
          <Column title={I18n(field, true)} dataIndex={field} key={field} />
        ))}
        <Column title={I18n("week_start", true)} dataIndex={"weekStart"} key={"weekStart"} />
        <Column
          title={I18n("modules", true)}
          dataIndex={"modulesNames"}
          render={(modules) => (
            <div>
              {I18n(modules[0], true)}{" "}
              <Dropdown
                data={modules.map((r: string, i: number) => ({ key: i, label: I18n(r, true) }))}
                text={modules.length >= 2 ? `+${modules.length}` : ""}
              />
            </div>
          )}
          key={"modulesNames"}
        />
        <Column
          title={I18n("users", true)}
          dataIndex={"usersName"}
          render={(users) => (
            <div>
              {users[users.length - 1]}{" "}
              <Dropdown
                data={users.map((r: string, i: number) => ({ key: i, label: r }))}
                text={users.length >= 2 ? `+${users.length}` : ""}
              />
            </div>
          )}
          key={"usersName"}
        />
        <Column
          title={I18n("type", true)}
          dataIndex={"type"}
          key={"type"}
          render={(value) => (
            <Tag
              className="rk-tag"
              icon={<FontAwesomeIcon icon={TYPE[value].icon} />}
              color={`${TYPE[value].color}`}
              key={value}
            >
              {I18n(value, true)}
            </Tag>
          )}
        />
        <Column
          title={I18n("configuration_status", true)}
          dataIndex={"state"}
          key={"state"}
          render={(value) => (
            <Tag
              className="rk-tag"
              icon={<FontAwesomeIcon icon={STATE[value].icon} />}
              color={`${STATE[value].color}`}
              key={value}
            >
              {I18n(value, true)}
            </Tag>
          )}
        />
        <Column
          title={I18n("state", true)}
          dataIndex={"active"}
          key={"active"}
          filteredValue={filteredStatus || null}
          filters={[
            { text: "Activo", value: true },
            { text: "Inactivo", value: false },
          ]}
          onFilter={(value, record: any) => record.active === value}
          render={(key) => (
            <Tag className="rk-tag" color={key ? "green" : "red"} key={key}>
              {key ? I18n("active", true) : I18n("inactive", true)}
            </Tag>
          )}
        />
        <Column
          key="action"
          render={() => [
            <span style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </span>,
          ]}
        />
      </TableUI>
    </>
  );
}
