import { Spin, Descriptions, Tag, Alert, Button } from "antd";
import { GetIntegratorById } from "apollo/integrator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faTimes } from "@fortawesome/free-solid-svg-icons";
import { I18n } from "i18n";
import { List } from "components";
import { ButtonActive } from "./active-interator";

interface NameProps {
  onCloseDrawer: () => void;
  integratorId: string;
}

export function DetailIntegrator({ integratorId, onCloseDrawer }: NameProps) {
  const { data: dataIntegratorById, isLoading, error } = GetIntegratorById(integratorId);

  if (integratorId && error && dataIntegratorById) {
    return <Alert message={I18n("internal_server_error", true)} type="error" />;
  }
    

// This is the function we wrote earlier
    async function copyTextToClipboard(text: string) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }

    const handleCopyClick = () => {
      copyTextToClipboard(dataIntegratorById?.apiKey);
    };

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Descriptions size="middle" column={1}>
            <Descriptions.Item label={I18n("name")}>
              {dataIntegratorById?.name}
            </Descriptions.Item>
            <Descriptions.Item className="rk-descriptions-items" label={I18n("api_key", true)}>
              {dataIntegratorById?.apiKey}
              <Button
                style={{ marginLeft: 'auto' }}
                icon={<FontAwesomeIcon icon={faCopy} />}
                size="large"
                onClick={handleCopyClick}
              />
            </Descriptions.Item>
            <Descriptions.Item label={I18n("description", true)}>
              {dataIntegratorById?.description}
            </Descriptions.Item>
            <Descriptions.Item label={I18n("state", true)}>
              {dataIntegratorById?.active ? (
                <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={"success"}>
                  {`  ${I18n("activate")}`}
                </Tag>
              ) : (
                <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={"error"}>
                  {`  ${I18n("inactive")}`}
                </Tag>
              )}
            </Descriptions.Item>
          </Descriptions>
          <div className="rk-container-list">
              <List
                formGroupLabel={`${I18n("modules", true)}  `}
                readMoreLabel={I18n("you_can_see_them_in_detail")}
                data={dataIntegratorById?.modules.map((r: any, i: number) => ({ value: I18n(r, true) })) || []} />
            </div>
            <div className="rk-button-group">
              <ButtonActive active={dataIntegratorById?.active} integratorId={integratorId} onCloseDrawer={() => onCloseDrawer()} />
            </div>
        </>
      )}
    </>
  );
}
