import { gql } from "@apollo/client";
import client from "apollo/apollo-client";

type IntegratorState = {
  _id: string;
};

export async function ActiveIntegrator({ _id }: IntegratorState) {
  return await new Promise<{ loading: boolean; error: boolean; data: any; message: string }>(
    (resolve, reject) => {
      client
        .mutate({
          mutation: MUTATION_INTEGRATOR,
          variables: { _id },
          refetchQueries: ["Integrators","IntegratorsById"],
        })
        .then(
          ({
            data: {
              IntegratorsState: { error, data, message },
            },
            loading,
          }: any) => {
            resolve({ data, loading, error, message });
          },
        )
        .catch((error: any) => {
          reject(new Error("_server_error_"));
        });
    },
  );
}

const MUTATION_INTEGRATOR = gql`
  mutation IntegratorsState($_id: ID!) {
    IntegratorsState(_id: $_id) {
      error
      data {
        _id
        name
        description
        active
        modules
      }
      message
    }
  }
`;
